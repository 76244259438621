.Hand {
	background-color: #EEEEDD;
	display: grid;
	grid-template-columns: 70% 30%;
	grid-template-rows: 33% 67%;
}

.Hand > :nth-child(1) {
	grid-row: 1 / span 2;
	grid-column: 1;
}
.Hand > :nth-child(2) {
	grid-row: 1;
	grid-column: 2;
}
.Hand > :nth-child(3) {
	grid-row: 2;
	grid-column: 2;
}

.HandCardsContainer {
	overflow-y: scroll;
}

.HandActionsContainer {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-template-rows: 100%;
}

.HandCategoriesContainer {
	overflow-y: auto;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 50% 50%;
}

.HandCategoriesContainer Button {
	font-size: 4vw;
}

.HandCard {
	font-size: 1.8vw;
	width: 20%;
	height: 20%;
	vertical-align: middle;
}