.Field {
	background-color: #DDDDFF;
	position: relative;
}

.FieldCardsContainer {
	height: 100%;
}

.FieldCard {
	position: absolute;
	width: 14%;
	height: 10%;
	min-height: 1.8vw;
}

.FieldCardButton {
	width: 100%;
	height: 100%;
	font-size: 2vw;
	padding: 0px;
}

.FieldActionsContainer {
	position: absolute;
	top: 8px;
	right: 8px;
}