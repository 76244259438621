.Button {
	background-color: #EEEEEE;
	border: 1px solid black;
	user-select: none;
}

.ButtonSelected {
	background-color: #FFFFAA;
}

.ButtonHighlighted {
	border-color: white;
}