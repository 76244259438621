.Chat {
	background-color: #FFFCCC;
	display: flex;
	flex-direction: column;
}

.ChatGuesses {
	background-color: #CCCCCC;
	flex: 1;
	overflow-y: scroll;
}

.ChatGuess {
	padding: 4px;
}

.ChatGuessUsername {
	font-style: italic;
}

.ChatGuesses .PendingGuess {
	color: gray;
}

.TargetWordContainer {
	background-color: #AAAAAA;
	padding: 4px;
}

.TargetWordLabel {
	font-size: 12px;
	font-style: italic;
}

.TargetWordWord {
	font-size: 16px;
}