
.GameEndWrapper {
  text-align:  center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index:  999;
  background-color: rgba(0, 0, 0, 0.2);
}

.GameEndContent {
  width: fit-content;
  margin-left:  auto;
  margin-right:  auto;
  margin-top: 24px;
  padding:  12px;
  background-color: #FFD;
}