.MainMenu {
	height:  100%;
	background-color:  #AAAAFF;
	text-align:  center;
}

.MainMenu .MainMenuBox {
	width: fit-content;
	padding: 8px;
	border:  1px solid black;
	border-radius:  12px;
	background-color:  #DDD;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 12px;
}

.MainMenuTitle {
	padding-top: 12px;
}

.MainMenuTitle p {
	font-weight:  bold;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	font-size:  22px;
	border:  1px solid black;
	border-radius:  12px;
	background-color:  #FFF;
}

.MainMenu Button, .MainMenu Input {
	margin-top: 8px;
}