html {
  touch-action: none;
}

html, body, #root {
  height: 100%;
}

Input[type="text"] {
  font-size: 16px;
}

.App {
  width: 96%;
  height: 98%;
  min-height: 200px;
  margin: auto;

  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.App.cluegiver > *, .App.guesser > * {
  overflow: hidden;
}

.App.cluegiver, .App.guesser {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 70% 30%;
  overflow: hidden;
}

@media (orientation : landscape) {
  .App > .Field {
    grid-column: 1;
  }
  .App > .Chat {
    grid-column: 2;
  }

  .App.cluegiver > .Field {
    grid-row: 1;
  }
  .App.cluegiver > .Chat {
    grid-row: 1;
  }
  .App.cluegiver > .Hand {
    grid-row: 2;
    grid-column: 1 / span 2;
  }

  .App.guesser > .Field {
    grid-row: 1 / span 2;
  }
  .App.guesser > .Chat {
    grid-row: 1 / span 2;
  }
}

@media (orientation : portrait) {
  .App.cluegiver, .App.guesser {
    grid-template-columns: 100%;
    grid-template-rows: 30% 40% 30%;
  }

  .App.cluegiver > .Chat {
    grid-row: 1;
  }
  .App.cluegiver > .Field {
    grid-row: 2;
  }
  .App.cluegiver > .Hand {
    grid-row: 3;
  }

  .App.cluegiver > .Chat {
    grid-row: 1;
  }
  .App.guesser > .Field {
    grid-row: 2 / span 2;
  }
}
