
.RoomOverlay {
  position: absolute;
  padding: 4px;
  background-color: #EED;
  z-index: 999;
}

@media (orientation : landscape) {
  .RoomOverlay {
    top: 0;
    left: 0;
  }
}


@media (orientation : portrait) {
  .RoomOverlay {
    top: 0;
    right: 0;
  }
}

.RoomOverlayTitle {
  font-size: 14px;
}

.RoomOverlayCode {
  text-align: center;
}

.RoomPlayers {
  font-size:  12px;
  margin-top: 8px;
}

.RoomPlayers > p {
  margin-top: 2px;
  margin-bottom: 2px;
}

.RoomPlayers > .CurrentPlayer {
  font-weight:  bold;
}